/*****************************************************************************************
 * errorResponseの処理周りの共通処理
 ****************************************************************************************/

export const statusAndErrorMessage = (errorResponse: any) => {
  let {status, data} = errorResponse
  if(status == undefined) status = 'javascript error'

  if(data === undefined || data.error_message === undefined) {
    return [status, null]
  } else {
    return [status, data.error_message]
  }
}
